(<template>
  <div class="awarder-inter-info">
    <section class="awarder-inter-info__int-data-cont">
      <interpreter-info :supplier-info="supplierInfo" />

      <!-- Verify Interpreter Component -->
      <digital-id v-if="inPersonAssignment && (isAcceptedJob || isInProgressJob)"
                  :interpreter-uid="interUid" />
    </section>
  </div>
</template>)

<script>
  import {mapState} from 'vuex';
  import InterpreterInfo from '@/components/shared_components/InterpreterInfo';
  import DigitalIdSection from '@/components/shared_components/DigitalIdSection';

  export default {
    components: {
      'interpreter-info': InterpreterInfo,
      'digital-id': DigitalIdSection
    },
    props: {
      contractInfo: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapState('OneAssignmentStore', {
        relation: ({currentDiscussion}) => currentDiscussion?.discussion?.supplierInfo?.relation,
        businessDiscussions: ({chatDiscussions}) => chatDiscussions?.discussions,
        assignmentType: ({storeJobObj}) => storeJobObj?.interpretationRequirement?.sessionType,
        languageFromId: ({storeJobObj}) => storeJobObj?.interpretationRequirement?.languageFromId,
        languageToId: ({storeJobObj}) => storeJobObj?.interpretationRequirement?.languageToId,
        interUid: ({currentDiscussion}) => currentDiscussion?.discussion?.supplierInfo?.uid,
        interQualificationId: ({currentDiscussion}) => currentDiscussion?.discussion?.supplierInfo?.qualification,
        assignmentObj: (state) => state.storeJobObj || {},
      }),
      interId() { return this.chatDiscussion?.supplierInfo?.person?.id; },
      supplierInfo() {
        const requiredSupplierInfo = {
          gender: this.genderName,
          relation: this.relation,
          showPoliceLogo: this.showPoliceLogo,
          qualification: this.interQualificationId,
          uid: this.interUid,
          id: this.interId
        };
        return {...this.contractInfo, ...requiredSupplierInfo};
      },
      assignmentInfo() {
        return {
          languageFromId: this.languageFromId,
          languageToId: this.languageToId
        };
      },
      chatDiscussion() {
        if (this.businessDiscussions) {
          for (const discussion of this.businessDiscussions) {
            if (discussion.id == this.$store.state.OneAssignmentStore.currentDiscussionId) {
              return discussion;
            }
          }
        }
        return {};
      },
      showPoliceLogo() { return this.chatDiscussion.supplierInfo ? this.chatDiscussion.supplierInfo.showPoliceLogo : false; },
      genderName() {
        const genders = this.$helperData.getSelectGenders(this);
        const gender = genders.find(({id}) => id == this.contractInfo.gender) || {};

        return gender.name;
      },
      inPersonAssignment() {
        return this.assignmentType == 'in_person';
      },
      jobStatus() { return this.assignmentObj.status || ''; },
      isAcceptedJob() { return this.jobStatus === 'accepted'; },
      isInProgressJob() { return this.jobStatus === 'in_progress'; },
    }
  };
</script>

<style scoped>
  .awarder-inter-info__int-data-cont {
    display: flex;
    flex-grow: 0;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  @media (max-width: 768px) {
    .awarder-inter-info__int-data-cont {
      flex-direction: column;
    }
  }
</style>
