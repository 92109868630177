// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/id_icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.digital-id[data-v-74feb235] {\n  width: 100%;\n  margin-top: 10px;\n  margin-left: auto;\n  border-radius: 5px;\n  background-color: #fff7e5;\n}\n.digital-id__title[data-v-74feb235] {\n  margin: 8px 15px 10px;\n  font-weight: bold;\n  font-size: 14px;\n}\n.digital-id__description[data-v-74feb235] {\n  margin: 10px 15px;\n  font-size: 10px;\n}\n.digital-id__verification-button[data-v-74feb235] {\n  display: -webkit-inline-box;\n  display: -ms-inline-flexbox;\n  display: inline-flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  margin: 0 15px 13px;\n  padding: 8px 15px 9px;\n  border: solid 1px #ff5b24;\n  border: solid 1px var(--color-secondary);\n  border-radius: 3px;\n}\n.digital-id__verification-button[data-v-74feb235]:hover {\n  background-color: #ffccb3;\n  background-color: var(--color-secondary-lighter);\n}\n.digital-id__verification-button-image[data-v-74feb235] {\n  width: 20px;\n  height: 13px;\n  margin-right: 10px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-image: var(--image-id-icon);\n  background-size: contain;\n}\n.digital-id__verification-button-text[data-v-74feb235] {\n  color: #ff5b24;\n  color: var(--color-secondary);\n}\n@media (max-width: 1024px) {\n.digital-id[data-v-74feb235] {\n    width: 100%;\n    margin-top: 20px;\n}\n}\n", ""]);
// Exports
module.exports = exports;
