(<template>
  <div class="digital-id">
    <h3 class="digital-id__title">{{ $gettext('Verify the interpreter (Digital ID)') }}</h3>
    <p class="digital-id__description">{{ $gettext('Compare today\'s picture with the picture the interpreter will show you on their Digital ID card in the app') }}</p>

    <button class="digital-id__verification-button"
            @click="openInterpreterIdModal">
      <figure class="digital-id__verification-button-image"></figure>
      <span class="digital-id__verification-button-text">{{ $gettext('Show image') }}</span>
    </button>
  </div>
</template>)

<script>
  export default {
    props: {
      interpreterUid: {
        type: String,
        default: ''
      }
    },
    methods: {
      openInterpreterIdModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'interpreter-id-modal',
          width: 333,
          data: {uid: this.interpreterUid}
        });
      }
    }
  };
</script>

<style scoped>
  .digital-id {
    width: 100%;
    margin-top: 10px;
    margin-left: auto;
    border-radius: 5px;
    background-color: #fff7e5;
  }

  .digital-id__title {
    margin: 8px 15px 10px;
    font-weight: bold;
    font-size: 14px;
  }

  .digital-id__description {
    margin: 10px 15px;
    font-size: 10px;
  }

  .digital-id__verification-button {
    display: inline-flex;
    align-items: center;
    margin: 0 15px 13px;
    padding: 8px 15px 9px;
    border: solid 1px #ff5b24;
    border: solid 1px var(--color-secondary);
    border-radius: 3px;
  }

  .digital-id__verification-button:hover {
    background-color: #ffccb3;
    background-color: var(--color-secondary-lighter);
  }

  .digital-id__verification-button-image {
    width: 20px;
    height: 13px;
    margin-right: 10px;
    background-image: url(~@assets/imgs/undefined_imgs/id_icon.svg);
    background-image: var(--image-id-icon);
    background-size: contain;
  }

  .digital-id__verification-button-text {
    color: #ff5b24;
    color: var(--color-secondary);
  }

  @media (max-width: 1024px) {
    .digital-id {
      width: 100%;
      margin-top: 20px;
    }
  }
</style>
